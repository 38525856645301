<template>
  <video-player
    class="video-player-box"
    :options="playerOptions"
    :playsinline="true"
  ></video-player>
</template>
<script type="text/ecmascript-6">
import { videoPlayer } from 'vue-video-player';
// import videoJsHls from 'videojs-contrib-hls';
require('video.js/dist/video-js.css');
require('vue-video-player/src/custom-theme.css');

export default {
  props: ['video'],
  data() {
    return {};
  },
  computed: {
    playerOptions() {
      let playerOptions = {
        // 视频播放器
        controls: true,
        sourceOrder: true,
        muted: false,
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, // 全屏按钮
        },
      };
      playerOptions.autoplay = this.video.autoplay;
      playerOptions.poster = this.video.poster;
      playerOptions.sources = this.video.sources;
      return playerOptions;
    },
  },
  components: { 'video-player': videoPlayer },
  methods: {
    play() {},
    pause() {},
  },
};
</script>
<style lang="scss">
.mediaCover {
  height: 100%;
}
.video-player-box {
  height: 100%;
  .vjs-poster {
    background-size: cover;
    background-color: transparent;
  }
  .video-js {
    width: 100%;
    height: 100%;
    background: transparent;
  }
}

.vjs-big-play-button {
  height: 1.6em !important;
  width: 50px !important;
  border-radius: 50% !important;
  top: 40% !important;
  left: 50% !important;
  transform: translate(-50%) !important;
  background-color: rgba(0, 0, 0, 0.2) !important;
}
// .vjs-big-play-button .vjs-icon-placeholder {
//   font-size: 0.7rem !important;
// }

// .vjs-control-bar {
//   background-color: transparent !important;
//   opacity: 0.8 !important;
//   background-image: linear-gradient(
//     -180deg,
//     #000000 0%,
//     rgba(0, 0, 0, 0) 97%
//   ) !important;
// }
// .vjs-icon-play:before {
//   //继续
//   content: '\F101';
// }
// .vjs-icon-replay:before {
//   //回放
//   content: '\F117';
// }
// .vjs-icon-pause:before {
//   //停止
//   content: '\F117';
// }
// .vjs-volume-panel.vjs-control.vjs-volume-panel-horizontal {
//   display: none;
// }
// .vjs-play-control.vjs-control.vjs-button.vjs-paused.vjs-ended {
//   width: 30px !important;
//   border-radius: 50% !important;
//   -webkit-box-flex: 0;
//   -ms-flex: none;
//   flex: none;
//   border: 1px solid #ffffff;
//   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
//   text-align: center;
//   margin: 8px 5px 8px 9px;
//   padding: 0;
//   font-size: 3em;
//   line-height: 20px;
//   height: 30px;
//   .vjs-icon-placeholder {
//     font-size: 0.3rem;
//   }
//   .vjs-progress-control.vjs-control {
//     left: 20px !important;
//   }
//   .vjs-button.vjs-playing,
//   .vjs-button.vjs-paused {
//     width: 30px !important;
//     border-radius: 50% !important;
//     -webkit-box-flex: 0;
//     -ms-flex: none;
//     flex: none;
//     border: 1px solid #ffffff;
//     box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
//     text-align: center;
//     margin: 8px 5px 8px 9px;
//     padding: 0;
//     font-size: 3em;
//     line-height: 20px;
//     height: 30px;
//     .vjs-icon-placeholder {
//       font-size: 0.3rem;
//     }
//   }
// }
</style>